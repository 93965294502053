import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Grid from '../components/grid';
import ArticlePreview from '../components/article-preview'
import styled from "styled-components"
import tw from "tailwind.macro"

const Articles = styled.section`
  ${tw`flex flex-col max-w-md`};
`

const Heading = styled.h1`
  ${tw`text-5xl font-black text-white relative mb-4`};
`

const StyledLink = styled(Link)`
  ${tw`relative text-red no-underline hover:text-black hover:no-underline`}
`

const Intro = styled.p`
  ${tw`text-2xl mb-0 text-grey-darkest leading-normal`};
`

const Time = styled.time`
  ${tw`block text-lg mb-8 font-mono text-grey-dark leading-normal`}
`

const Body = styled.section`
  ${tw`text-xl mb-8`}
`

class TagTemplate extends React.Component {
    render() {
        const tagName = get(this.props, 'pageContext.slug')
        const posts = get(this.props, 'data.allContentfulBlogPost.edges')
        const siteTitle = get(this.props, 'data.site.siteMetadata.title')

        return (
            <Layout location={this.props.location} >
                <Articles>
                    <h1 className="mt-12 pb-12 border-b-2 border-grey-lighter">#{tagName} Articles</h1>

                    {posts.map(({ node }) => {
                        return (
                            <article key={node.slug}>
                                <ArticlePreview article={node} />
                            </article>
                        )
                    })}
                </Articles>
            </Layout>
        )
    }
}

export default TagTemplate

export const pageQuery = graphql`
  query BlogPostsByTag($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(filter:{tags:{eq:$slug}}) {
        edges {
            node {
                title
                slug
                publishDate(formatString: "MMMM Do, YYYY")
                tags
                heroImage {
                    fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
                        ...GatsbyContentfulFluid
                    }
                }
                description {
                    childMarkdownRemark {
                    html
                    }
                }
            }
        }
    }
  }
`
